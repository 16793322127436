<template>
	<div class="land-plant-detail">
		<div class="el-content plant-order-detail mb12" v-if="detail.order">
			<div class="plant-od-number">单号：{{detail.order.order_number}}</div>
			<div class="plant-od-opera">
				<a-space>
					<com-pay-result :id="detail.order.id" :type="7"></com-pay-result>
					<a-button @click="printOrder" v-has="{action:'wxapp_land_order_print',plat:is_shop}">打印订单</a-button> 
					<a-button @click="refundOrder" type="primary" v-if="detail.order.is_refund!=2">退款</a-button>
					<!-- 查询订单支付状态 -->
				</a-space>
				<div class="plant-od-opera-status">
					<div class="lodos-row">
						<div>状态</div>
						<div>
							<span>{{$status.getPlantOrderStatus(detail.order)}}</span>
						</div>
					</div>
					<div class="lodos-row">
						<div>订单金额</div>
						<div>￥{{detail.order.total_price}}</div>
					</div>
				</div>
			</div>
			
			<div class="plant-od-cont">
				<div class="plant-odc-item">
					<p>用户：{{detail.user.user_name}} (电话： {{detail.user.phone}} )</p>
					<p>土地名称：{{detail.land.land_name}}</p>
					<p>土地编号：{{detail.spec.land_num}}</p>
				</div>
				
				<div class="plant-odc-item">
					<!-- <p  v-if="detail.ser">服务类型：{{detail.ser.name}}</p> -->
					<p >创建时间：{{detail.order.create_time}}</p>
				</div>
			</div>
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">种植列表</div>
			<com-plant-list :list="detail.plant" @success="getPlantOrderDetail()"></com-plant-list>
		</div>
	</div>
</template>

<script>
import comPayResult from '@/components/miniapp/com-pay-result.vue'
import comPlantList from '@/components/layout/land/com-plant-list.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import landModel from '@/api/land.js'
import common from '@/api/common.js'
export default{
	name:'page-plant-detail',
	components:{
		comPayResult,
		comPlantList
	},
	props:{
		is_shop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			order_id:0,
			detail:{},
			show:{
				payResult:false
			}
		})
		const opt = useRoute().query
		getPlantOrderDetail()
		function getPlantOrderDetail(){
			landModel.getPlantOrderDetail(opt.id,res=>{
				_d.detail = res
			})
		}
		const refundOrder = ()=>{
			landModel.setPlantOrder('refund_yes',opt.id,'确认对该订单进行退款操作吗？',()=>getPlantOrderDetail())
		}
		const printOrder = ()=>common.printOrder(opt.id,4)
		return{
			...toRefs(_d),
			getPlantOrderDetail,
			refundOrder,
			printOrder
		}
	}
}
</script>

<style lang="scss">
	.plant-order-detail{
		.plant-od-number{
			font-size: 18px;
			font-weight: bold;
		}
		
		.plant-od-cont{
			display: flex;
			margin-top: 16px;
			margin-bottom: 16px;
			
			.plant-odc-item{
				width: 320px;
				font-size: 14px;
				line-height: 30px;
				color: #333;
				p{
					margin: 0;
				}
			}
		}
		
		.plant-od-opera{
			position: absolute;
			right: 3%;
			margin-top: -30px;
			text-align: right;
		
			&-status{
				display: flex;
				justify-content: flex-end;
				.lodos-row{
					text-align: right;
					margin-top: 16px;
					margin-left: 24px;
					
					div:first-child{
						color: #999;
						font-size: 14px;
					}
					
					div:last-child{
						color: #333;
						font-size: 18px;
						margin-top: 8px;
					}
				}
			}
		}
		
	}
</style>
